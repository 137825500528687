<template>
  <div class="d-flex flex-column align-items-center col-12">
    <div class="navbar-container">
      <div v-if="windowWidth >= 500">
        <DesktopNavBar/>
      </div>
      <div v-if="windowWidth < 500">
        <MobileNavBar/>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center dashboard-container col-12">
      <button @click="logoutClicked()" v-if="windowWidth >= 500" class="logout-button">Logout</button>
      <div class="header-text mt-5">Approve or Deny Leave!</div>
      <div class="table-header mt-4">Pending Leave</div>
      <div class="d-flex flex-column col-12 col-xl-6 mt-1 mb-3">
        <div class="leave-table-container" v-for="(staff) in pendingLeaveTable" :key="staff.id">
          <div class="staff-surname orange-text p-1 mt-3">{{ staff[0].Surname }}</div>
          <div class="d-flex flex-row align-items-center col-12 leave-row p-2" v-for="(leave) in staff" :key="leave.id">
            <div class="col-2">{{ leave.LeaveType }}</div>
            <div class="col-3">{{ this.formatDate(leave.StartDate) }}</div>
            <div class="col-3">{{ this.formatDate(leave.EndDate) }}</div>
            <div class="col-2">{{ leave.DurationHours * 3 / 24 + ' Day(s)' }}</div>
            <div class="d-flex flex-row align-items-center col-3 approve-deny-text">
              <div @click="processLeaveRequest(leave, 1)" v-if="windowWidth >= 500"
                   class="d-flex flex-row align-items-center green-text col-6">
                <img class="col-3" src="../../assets/approve-icon.png">
                <div>Approve</div>
              </div>
              <div @click="processLeaveRequest(leave, 1)" v-if="windowWidth < 500"
                   class="d-flex flex-row align-items-center green-text col-3">
                <img class="col-12" src="../../assets/approve-icon.png">
              </div>
              <div @click="processLeaveRequest(leave, 0)" v-if="windowWidth >= 500"
                   class="d-flex flex-row align-items-center red-text col-6">
                <img class="col-3" src="../../assets/deny-icon.png">
                <div>Deny</div>
              </div>
              <div @click="processLeaveRequest(leave, 0)" v-if="windowWidth < 500"
                   class="d-flex flex-row align-items-center green-text col-3">
                <img class="col-12" src="../../assets/deny-icon.png">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-header mt-4">Past Approved or Denied Leave</div>
      <div class="d-flex flex-column col-12 col-xl-6 mt-2 mb-5">
        <div class="leave-table-container" v-for="(staff) in groupedLeaveTable" :key="staff.id">
          <div class="staff-surname orange-text p-1 mt-3">{{ staff[0].Surname }}</div>
          <div class="d-flex flex-row col-12 leave-row p-2" v-for="(leave) in staff" :key="leave.id">
            <div class="col-2">{{ leave.LeaveType }}</div>
            <div class="col-3">{{ this.formatDate(leave.StartDate) }}</div>
            <div class="col-3">{{ this.formatDate(leave.EndDate) }}</div>
            <div class="col-2">{{ leave.DurationHours * 3 / 24 + ' Day(s)' }}</div>
            <div :class="textColourChoice(leave.Approved)" class="col-2">{{
                this.returnApprovedLeaveText(leave.Approved)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileNavBar from "@/views/components/mobile/MobileNavBar";
import DesktopNavBar from "@/views/components/desktop/DesktopNavBar";
import {ref} from 'vue'
import {mapMutations, mapState} from "vuex";
import DashboardService from "@/services/dashboardService";
import moment from "moment";

const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

export default {
  name: "EmployeeLeave",
  components: {MobileNavBar, DesktopNavBar},
  computed: {
    ...mapState(['userData']),
  },
  data() {
    return {
      windowWidth: windowWidth,
      pendingLeaveTable: [],
      groupedLeaveTable: [],
    }
  },
  methods: {
    ...mapMutations(['setUserData', 'setIsLoading']),
    logoutClicked() {
      this.setUserData(undefined);
      this.routeToPage('/login')
    },
    async getEmployeeLeaveData() {
      const response = await DashboardService.getAllEmployeeRequestedLeave();
      this.filterPendingLeave(response, null)
      this.filterHistoricalLeave(response)
    },
    returnApprovedLeaveText(boolean) {
      return boolean ? 'Approved' : 'Denied';
    },
    formatDate(date) {
      return moment(date.toString().slice(0, 10)).format('DD-MM-Y');
    },
    filterPendingLeave(leaveData, approvedStatus) {
      const pendingLeaveData = leaveData.filter(entry => entry.Approved === approvedStatus);
      this.pendingLeaveTable = this.groupBySurname(pendingLeaveData.reverse());
    },
    filterHistoricalLeave(leaveData) {
      const leaveStatus = [true, false];
      const historicalLeaveData = leaveData.filter(entry => leaveStatus.includes(entry.Approved));
      this.groupedLeaveTable = this.groupBySurname(historicalLeaveData.reverse());
    },
    groupBySurname(historicalLeaveData) {
      return historicalLeaveData.reduce((result, entry) => {
        const surname = entry.Surname;

        if (!result[surname]) {
          result[surname] = [];
        }

        result[surname].push(entry);

        return result;
      }, {});
    },
    textColourChoice(leaveStatus) {
      return leaveStatus ? 'orange-text' : 'red-text';
    },
    async processLeaveRequest(employee, approvedBoolean) {
      await DashboardService.approveDenyEmployeeLeave(employee.LeaveId, approvedBoolean);
      await this.communicateAdminLeaveDecisionViaSMS(employee, approvedBoolean);
      await this.communicateApprovedLeaveDecisionToSthe(employee, approvedBoolean);
      await this.getEmployeeLeaveData();
    },
    async communicateAdminLeaveDecisionViaSMS(employee, approvedBoolean) {
      const leaveStatus = approvedBoolean ? 'approved' : 'denied';

      const smsData = {
        msisdn: employee.Msisdn,
        text: `PENROSE NOTIFICATION. Hi ${employee.Surname}! ${employee.LeaveType} leave ${leaveStatus} by Admin. From the ${this.formatDate(employee.StartDate)} to the ${this.formatDate(employee.EndDate)}. ${employee.DurationHours * 3 / 24} day(s)`
      };

      await DashboardService.sendSMS(smsData);
    },
    async communicateApprovedLeaveDecisionToSthe(employee, approvedBoolean) {
      const leaveStatus = approvedBoolean ? 'approved' : 'denied';

      if (leaveStatus === 'approved') {
        const smsData = {
          msisdn: 27817678335,
          text: `PENROSE NOTIFICATION. Hi Sthe! ${employee.Surname} ${employee.LeaveType} leave ${leaveStatus} by Admin. From the ${this.formatDate(employee.StartDate)} to the ${this.formatDate(employee.EndDate)}. ${employee.DurationHours * 3 / 24} day(s)`
        };

        await DashboardService.sendSMS(smsData);
      }
    }
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getEmployeeLeaveData();
    this.setIsLoading(false);
  },
  mounted() {
    window.addEventListener('resize', handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', handleResize)
  },
}
</script>

<style scoped>
.dashboard-container {
  position: relative;
}

.header-text {
  font-size: 60px;
  color: #EE5C36;
  line-height: 1.1;
  margin-left: 30px;
}

.staff-surname {
  font-size: 18px;
}

.orange-text {
  color: #EE5C36;
}

.green-text {
  color: #06c506;
}

.red-text {
  color: #FF0303FF;
}

.table-header {
  font-size: 25px;
}

tr {
  height: 40px;
}

.leave-table-container, tr {
  border-bottom: 1px solid #000000;
}

th {
  font-size: 18px;
  color: #EE5C36;
}

.leave-row {
  border-top: 1px solid #000000;
}

.approve-deny-text {
  font-size: 12px;
}

.logout-button {
  position: absolute;
  background-color: #EE5C36;
  color: #FFFFFF;
  font-size: 13px;
  right: 10px;
  top: 10px;
  border: 0;
  height: 36px;
  width: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media screen and (max-width: 500px ) {
  .header-text {
    font-size: 40px;
  }

  .table-header {
    font-size: 20px;
  }

  tr {
    font-size: 10px;
  }

  th {
    font-size: 14px;
    color: #EE5C36;
  }

  .leave-row {
    font-size: 10px;
  }
}
</style>
